import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WarningLevel } from 'src/app/framework/enums';
import { UserAuthenticated } from 'src/app/pages/security/login/models/user-authenticated.model';
import { AuthenticateService } from 'src/app/pages/security/login/services/authenticate.service';
import { AuthService } from 'src/app/services/application/auth.service';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { isNullOrUndefined } from 'util';
import { FirstAccessContact } from '../../../models/first-access-contact.model';
import { NewPassword } from '../../../models/new-password.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';
import { UserPasswordService } from '../../../services/user-password.service';

declare var $: any;

@Component({
  selector: 'app-first-access-password',
  templateUrl: './first-access-password.component.html',
  styleUrls: ['./first-access-password.component.scss']
})
export class FirstAccessPasswordComponent implements OnInit {

  formModal: FormGroup;

  private _attempt = false;

  constructor(
    private userPasswordService: UserPasswordService,
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private authService: AuthService,
    private context: ContextService,
    private fb: FormBuilder) {
      this.formModal = this.fb.group({
        email: ['', []],
        phone: ['', []]
      });
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get cpf(): AbstractControl { return this.navigationService.form.get('cpf'); }
  get cnpj(): AbstractControl { return this.navigationService.form.get('cnpj'); }
  get userType(): AbstractControl { return this.navigationService.form.get('userType'); }
  get password(): AbstractControl { return this.navigationService.form.get('password'); }
  get passwordConfirm(): AbstractControl { return this.navigationService.form.get('passwordConfirm'); }

  get email(): AbstractControl { return this.formModal.get('email'); }
  get phone(): AbstractControl { return this.formModal.get('phone'); }

  ngOnInit() {
    this.navigationService.clearValidationsForm();
    this.setValidationsForm();
  }

  async save(): Promise<void> {
    this._attempt = true;
    if (this.navigationService.form.invalid) {
      return;
    }

    const model = new NewPassword(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.password.value,
      this.passwordConfirm.value);

    await this.userPasswordService.saveNewPassword(model)
      .toPromise()
      .then(response => this.success(response))
      .catch(error => this.error(error));
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: WarningLevel.Error, message: error }, () => { });
  }

  private setValidationsForm(): void {
    this.password.setValidators([Validators.required, Validators.minLength(8)]);
    this.passwordConfirm.setValidators([Validators.required, Validators.minLength(8)]);
  }

  update() {
    if (this.formModal.invalid) {
      return;
    }
    const model = new FirstAccessContact(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.email.value,
      this.phone.value
    );

    this.firstAccessService.updateContact(model)
      .subscribe(response => {
        $('#modal-first-access').modal('hide');
        this.authService.setUserDocument = !isNullOrUndefined(this.cpf.value) ? this.cpf.value : this.cnpj.value;
        // this.context.navigation.changeRoute('/main/my-documents');
      },
        error => this.error(error));
  }

  private success(response: UserAuthenticated): void {
    this.context.authService.initSession(
      response.authToken,
      (new Date(response.expiresAt)),
      response.id.toString(),
      response.name,
      response.email,
      response.privilege,
      response.role);

    if (response.firstAccess) {
      this.email.setValue(response.email || '');
      this.phone.setValue(response.phone || '');
      $('#modal-first-access').modal('show');
    } else {
      this.authService.setUserDocument = !isNullOrUndefined(this.cpf.value) ? this.cpf.value : this.cnpj.value;
      this.context.navigation.changeRoute('/main/meeting');
    }
  }

}
