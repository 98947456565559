import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/services/application/context.service';
import { Router } from '@angular/router';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { ScopeParametersService } from 'src/app/services/business/scope-parameters.service';
import { Storage } from "../../framework/storage";
import { ScopeParametersBatchMeetingService } from 'src/app/services/business/scope-parameters-batch-meeting.service';
import { IPermission } from 'src/app/models/IProfile';
import { permissions } from 'src/app/app-permissions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public scopeLogo: string;
  public scopeName: string;
  public sector: { name: string, brandImageUrl: any } = { name: 'Bem-vindo(a)', brandImageUrl: null }
  public sectorImageUrl: string;
  public hasAccessControl: boolean = false;
  public isAdminUser: boolean = false;
  public fileStreamCO3: boolean = false;

  //public user.role: number;
  public scope: any;
  public user: any;
  public listFiles = false;
  public batchMeetingEnabled = false;

  constructor(
    private router: Router,
    private context: ContextService,
    private scopeParametersService: ScopeParametersService,
    private scopeParameterBatchMeetingService: ScopeParametersBatchMeetingService) {

    this.user = this.context.getUser();
    this.scope = this.context.authService.currentScope;
    if (this.scope) {
      this.scopeLogo = this.scope.brandImageUrl;
      this.scopeName = this.scope.name;
    }
    if (this.sector == null) {
      this.router.navigate(['/login']);
    } else {
      this.sectorImageUrl = this.sector.brandImageUrl;
    }

  }

  ngOnInit() {
    this.isAdminUser = this.context.authService.userRole != 15;
    this.getScopeParameters();
    this.getScopeParametersBatchMeeting();
  }

  get appPermissions() {
    return permissions;
  }

  public getScopeParameters() {
    const queryParams: IQueryParams[] = [];
    queryParams.push({
      name: 'idScope',
      value: this.context.authService.currentScope.id,
      type: 'query'
    });
    this.scopeParametersService.search(queryParams).subscribe(res => {
      this.hasAccessControl = res.content[0].HasIpAccessControl && this.context.authService.currentScope.key != "daycoval";
      this.fileStreamCO3 = res.content[0].FileStreamCO3;
    });
  }

  public getScopeParametersBatchMeeting() {
    const queryParams: IQueryParams[] = [];
    queryParams.push({
      name: 'idScope',
      value: this.context.authService.currentScope.id,
      type: 'query'
    });
    this.scopeParameterBatchMeetingService.search(queryParams).subscribe(param => {
      if (param != null && param.content.length > 0) {
        this.listFiles = param.content[0].ListFiles;
        this.batchMeetingEnabled = param.content[0].Enabled;
      }
    });
  }

  hideMenus(menuName: string): boolean {
    const permissions: IPermission[] = this.context.cache.getTemporaryValue('permissions') || [];

    if (this.scope.storage.hideMenu && this.scope.storage.hideMenu.includes(menuName)) {
      return true;
    }

    if (permissions.findIndex(permission => permission.name === menuName) < 0) {
      return true;
    }

    return false;
  }

  disableMenu() {
    return Storage.getString("password_recovery");
  }

  dynamicfundMenuDesc() {
    let menuName = "Fundos";
    let customLayout = this.scope.customLayout;
    if (customLayout != null && customLayout.fundLayout != null) {
      menuName = customLayout.fundLayout.menuName;
    }

    return menuName;
  }

  dynamicManagerMenuDesc() {
    let menuName = "Gestores";
    let customLayout = this.scope.customLayout;
    if (customLayout != null && customLayout.managerLayout != null) {
      menuName = customLayout.managerLayout.menuName;
    }

    return menuName;
  }

  public canAccess(permissionName){
    return this.context.canAccess(permissionName);
  }

}
