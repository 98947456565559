import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { SecurityService } from "src/app/services/business/security.service";
import { environment } from "src/environments/environment";
import { ContextService } from "src/app/services/application/context.service";
import * as FW from "../../../framework/core";
import { of, from, Observable } from "rxjs";
import { UserTwoFactor } from "src/app/pages/security/login/models/user-twofactor.model";
import { concatMap, switchMap, tap } from "rxjs/operators";
import { IQueryParams } from "src/app/models/IQueryParams";
import { UIBehavior } from "src/app/ui/ui-behavior";
import { IForgotPassword } from "src/app/models/IForgotPassword";
import { MessageSwalService } from "src/app/services/application/messageswal.service";
import { AuthService } from "src/app/services/application/auth.service";
import { ActivatedRoute } from "@angular/router";
import { WarningLevel } from "src/app/framework/enums";
import { IpRestrictionControlService } from "src/app/components/ip-restriction-control/services/ip-restriction-control.service";
import { ILoginResult } from "src/app/models/ILoginresult";
import { isNullOrEmpty } from 'src/app/framework/core';
import { Storage } from "../../../framework/storage";
import { AuthConfig, JwksValidationHandler, OAuthService } from "angular-oauth2-oidc";
import { IScope } from "src/app/models/IScope";
import { ProfileService } from "src/app/services/business/profile.service";

declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public _twoFactorStep: string = "confirmMail";
  public _twoFactor: UserTwoFactor = new UserTwoFactor();
  public _twoFactorStatus: boolean = false;
  public _twoFactorErrorMsg: string = "";
  private _userName: string;
  private _email: string;
  private _password: string;
  private _hasLoadedScope: boolean;
  private _hasIpAccessControl: boolean;
  private _hasTwoFactorAuth: boolean;
  private _scopeLogo: string;
  private _scopeName: string;
  private _redirectPage: string = "/main/meeting";
  private _cryptoText: string;
  private _loginResult: ILoginResult;
  private adfsAuthentication = false;

  public _redirectToUrl: string = null;
  private _scopeKey: string = null;
  public redirectToUrl: string = null;
  public captchatoken: string = null;
  public emailRecoveryPassword: string;
  public recoveringPassword = false;
  counter: { min: number, sec: number };
  public countDownTimer: string;

  public get scopeName(): string {
    return this._scopeName;
  }
  public set scopeName(v: string) {
    this.cdRef.detectChanges();
    this._scopeName = v;
  }

  public get scopeLogo(): string {
    return this._scopeLogo;
  }
  public set scopeLogo(v: string) {
    this.cdRef.detectChanges();
    this._scopeLogo = v;
  }

  public get userName(): string {
    return this._userName;
  }
  public set userName(v: string) {
    this._userName = v;
  }

  public get email(): string {
    return this._email;
  }
  public set email(v: string) {
    this._email = v;
  }

  public get password(): string {
    return this._password;
  }
  public set password(v: string) {
    this._password = v;
  }

  public get hasLoadedScope(): boolean {
    return this._hasLoadedScope;
  }
  public set hasLoadedScope(v: boolean) {
    this.cdRef.detectChanges();
    this._hasLoadedScope = v;
  }

  public get isAdfsAuthentication(): boolean {
    return this.adfsAuthentication;
  }
  public set isAdfsAuthentication(newValue: boolean) {
    this.adfsAuthentication = newValue;
  }

  public appVersion: string;

  constructor(
    private securityService: SecurityService,
    private context: ContextService,
    private messageService: MessageSwalService,
    private ipRestrictionControlService: IpRestrictionControlService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private profileService: ProfileService
  ) {
    $(".tooltip").remove();
    //this.context.authService.logoff();
    this._hasLoadedScope = false;
    this.appVersion = environment.version;
    Storage.setString('votation', '');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $(".grecaptcha-badge").addClass("show");
    this.loadScope();
    this.startCaptcha();
    if (this._scopeKey === "validadministrativo") {
      this._redirectPage = "/main/scopes";
    }
    this.route.queryParams.subscribe((params) => {
      if (params.vr) {
        Storage.setString('votation', params.vr);
      }

      if (params.redirectTo) {
        Storage.clear();
        this.context.navigation.changeRoute("/login", { "scope": params.scope })
      }
    });
  }

  startCaptcha() {
    // let self = this;
    // grecaptcha.ready(function () {
    //   grecaptcha.execute('6LdRpJYaAAAAAFkWgL3P0iAQ_fs3lTinlCABiBuL', { action: "Login" }).then(function (token) {
    //     self.captchatoken = token;
    //   });
    // });
  }

  doLogin() {

    if (this.canLogin() === false) {
      return;
    }

    this._twoFactorStep = "confirmMail";
    const targetRedirectUrl: string = this.redirectToUrl;
    from(
      grecaptcha.execute("6LdRpJYaAAAAAFkWgL3P0iAQ_fs3lTinlCABiBuL", {
        action: "Login",
      })
    )
      .pipe(
        tap((token: string) => {
          this.captchatoken = token;
        }),
        concatMap(() =>
          this.securityService.authenticate(
            this._scopeKey,
            this._userName,
            this._password,
            this.captchatoken
          )
        ),
        concatMap((loginResult) => {
          if (!FW.isNull(loginResult)) {
            if (loginResult.hasTwoFactor) {
              this._cryptoText = loginResult.authToken;
              this._loginResult = loginResult;
            } else if (loginResult.hasIpAccessControl) {
              this.validateIpAddress();
              this._cryptoText = loginResult.authToken;
              this._loginResult = loginResult;
            } else {
              this.context.authService.initSession(
                loginResult.authToken,
                new Date(loginResult.expiresAt),
                loginResult.id,
                loginResult.name,
                loginResult.email,
                loginResult.privilege,
                loginResult.role
              );
            }
            return of(loginResult);
          }
        }),
      ).subscribe((loginResult) => {
        return this.loadPermissions(loginResult, targetRedirectUrl);
      }, (error) => {
        this.error(error.error);
      });
  }

  validateIpAddress() {
    //get ip address and validate it
    fetch("https://api.ipify.org?format=json").then((res) => {
      res.json().then((res) => {
        const queryParams: IQueryParams[] = [];

        queryParams.push({
          name: "ip",
          value: res.ip,
          type: "query",
        });

        //access needs to be active
        queryParams.push({
          name: "status",
          value: 1,
          type: "query",
        });

        //scope
        queryParams.push({
          name: "scope",
          value: this._scopeKey,
          type: "query",
        });

        //verify if ip exists in db
        this.ipRestrictionControlService.search(queryParams).subscribe(
          (res) => {
            // this.context.authService.isIpAddressValidated = true;
            var today = new Date();
            today.setHours(today.getHours() + 4);
            this.context.authService.initSession(
              this._loginResult.authToken,
              today,
              this._loginResult.id,
              this._loginResult.name,
              this._loginResult.email,
              this._loginResult.privilege,
              this._loginResult.role
            );

            if (res.role === 63 && !!res.firstAccess) {
              this.context.navigation.changeRoute('main/alterpassword');
            } else {
              this.context.navigation.changeRoute(this._redirectPage);
            }
          },
          (error) => {
            this.messageService.displayMessage({
              message:
                "Acesso Bloqueado, favor entrar em contato com a central Administrativa. ",
              warninglevel: WarningLevel.Error,
            });
          }
        );
      });
    });
  }

  public startPasswordRecovery() {
    this.recoveringPassword = true;
  }

  public cancelPasswordRecovery() {
    this.recoveringPassword = false;
  }

  public recoveryPassword() {

    if (!this.IsEmail(this.emailRecoveryPassword)) {
      this.messageService.displayMessage({
        message:
          "O formato do email está inválido.",
        warninglevel: WarningLevel.Warning,
      });
      return;
    }

    let content: IForgotPassword = {
      email: this.emailRecoveryPassword,
      scope: this._scopeKey,
      captchaValue: this.captchatoken,
    };

    this.securityService.recoveryPassword(content).subscribe((resp) => {
      if (resp.content != null) {
        //$(".grecaptcha-badge").removeClass("show");
        //$("#modal-password").modal("hide");
        this.messageService.displayMessage(
          {
            warninglevel: WarningLevel.Success,
            message: "E-mail enviado com sucesso",
          },
          () => { }
        );
        localStorage.setItem("recoveryTime", JSON.stringify(this.timeWithFifteenMinutes()));
        this.startTimer();
      } else {
        this.messageService.displayMessage(
          {
            warninglevel: WarningLevel.Info,
            message: "Usuário não cadastrado. Favor entrar em contato com a Central de Atendimento.",
          },
          () => { }
        );
        localStorage.setItem("recoveryTime", JSON.stringify(this.timeWithFifteenMinutes()));
      }
      this.closeRecoveryPassword();
    });
  }

  public sendToken() {
    this.securityService.createTwoFactorToken(this._twoFactor).subscribe(
      (res) => { },
      (error) => { }
    );

    this._twoFactorErrorMsg = null;
    this._twoFactorStep = "confirmToken";
  }

  public validateToken() {
    this.securityService.validateTwoFactorToken(this._twoFactor).subscribe(
      (res) => {
        if (res.warningLevel == WarningLevel.Error) {
          this._twoFactorErrorMsg = res.message;
        } else {
          $("#exampleModalCenter").modal("hide");
          // this.context.authService.isTwoFactorAuthenticated = true;
          res = res.content;

          if (this._hasIpAccessControl) {
            this._loginResult.authToken = res.authToken;
            this._loginResult.id = res.id;
            this._loginResult.name = res.name;
            this._loginResult.email = res.email;
            this._loginResult.privilege = res.privilege;
            this._loginResult.role = res.role;

            this.validateIpAddress();
          } else {
            this.context.authService.initSession(
              res.authToken,
              new Date(res.expiresAt),
              res.id,
              res.name,
              res.email,
              res.privilege,
              res.role
            );

            this.context.navigation.changeRoute(this._redirectPage);
          }
        }
      },
      (error) => {
        error("Erro ao validar Token, tente novamente.");
      }
    );
  }

  public closeRecoveryPassword() {
    this.emailRecoveryPassword = null;
  }

  disableSend() {
    if (
      this.emailRecoveryPassword &&
      this.IsEmail(this.emailRecoveryPassword)
    ) {
      return false;
    }
    return true;
  }

  public IsEmail(email) {
    let er =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (er.exec(email)) {
      return true;
    } else {
      return false;
    }
  }

  formInvalid() {
    if (!this._userName) return true;
    if (!this.password) return true;
    return false;
  }

  public loadScope() {
    let host: string = environment.testEnvironment;

    if (environment.production) {
      host = window.location.hostname;
      if (!host) host = "";
      // this._scopeKey = host.replace(".valid.fund", "");
      this._scopeKey = host.split('.')[0]
    } else {
      if (environment.isHomolog) {
        this._scopeKey = this.context.navigation.getQueryParameter("scope");
      } else {
        host = environment.testEnvironment;
        if (!host) host = "";
        // this._scopeKey = host.replace(".valid.fund", "");
        this._scopeKey = host.split('.')[0]
      }
    }

    this.securityService.getScopeByKey(this._scopeKey).subscribe((scope) => {
      if (!!scope.alternativeDomain && environment.useAlternativeDomain) {
        window.location.href = `https://${scope.alternativeDomain}`;
      } else {
        this.context.authService.currentScope = scope;
        scope.name = "";
        this.scopeLogo = scope.brandImageUrl;
        this.scopeName = scope.name;
        this._hasLoadedScope = true;
        document.body.style.setProperty('--theme-primary', this.authService.currentScope.baseColor);
        this.cdRef.detectChanges();
        UIBehavior.changeTheme(scope.theme);
        this.configure(scope);
      }
    }, () => this.getScopeByDomain(host));
  }

  public getScopeByDomain(domain: string) {
    this.securityService.getScopeByAlternativeDomain(domain).subscribe((scope) => {
      this.context.authService.currentScope = scope;
      this._scopeKey = scope.key;
      scope.name = "";
      this.scopeLogo = scope.brandImageUrl;
      this.scopeName = scope.name;
      this._hasLoadedScope = true;
      document.body.style.setProperty('--theme-primary', this.authService.currentScope.baseColor);
      this.cdRef.detectChanges();
      UIBehavior.changeTheme(scope.theme);
      this.configure(scope);
    });
  }

  private error(error: string): void {
    this.messageService.displayMessage(
      { warninglevel: WarningLevel.Error, message: error },
      () => { }
    );
  }

  timeWithFifteenMinutes() {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    return date;
  }

  get time(): string {
    this.startTimer();
    return this.timeWithHours;
  }

  get timeWithHours(): string {
    return this.countDownTimer;
  }

  startTimer() {
    if (!isNullOrEmpty(localStorage.getItem('recoveryTime'))) {
      var expirationTimeDate = new Date(JSON.parse(localStorage.getItem('recoveryTime')));

      var expirationTime = expirationTimeDate.getTime();

      var timeNow = Date.now();

      if (timeNow < expirationTime) {
        this.startTimerWithHours(expirationTime);
      }
    }
  }

  startTimerWithHours(expirationTime: any) {
    var timer = expirationTime, hours, minutes, seconds;
    const intervalId = setInterval(() => {

      let diff = (((timer - Date.now()) / 1000) | 0);

      hours = (diff / 3600) | 0;
      minutes = ((diff % 3600) / 60) | 0;
      seconds = (diff % 60) | 0;

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.countDownTimer = hours + ":" + minutes + ":" + seconds;
      if (--diff < 0) {
        clearInterval(intervalId);
        localStorage.removeItem('recoveryTime');
        this.countDownTimer = null;
      }
    }, 1000);
  }

  public doLoginWithSSO() {
    this.oauthService.initLoginFlow();
  }

  public canLogin() {
    try {
      return this.context.authService.ssoNotAuthorized === false;
    }
    catch {
      return true;
    }
  }

  private configure(scope: IScope) {
    if (scope.adfs && scope.adfs.enabled)
      this.oauthService.configure(new AuthConfig({
        issuer: scope.adfs.issuer,
        redirectUri: scope.adfs.redirectUri,
        clientId: scope.adfs.clientId,
        loginUrl: scope.adfs.loginUrl,
        tokenEndpoint: `${scope.adfs.issuer}/as/token.oauth2`,
        userinfoEndpoint: `${scope.adfs.issuer}/idp/userinfo.openid`,
        scope: "openid",
        responseType: 'token id_token',
        openUri: (uri) => {
          let parameters = uri.split('?')[1];
          let url = `${scope.adfs.loginUrl}&${parameters}`
          window.location.href = url;
        }
      }));

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    if (environment.production && this.context.authService.ssoNotAuthorized === false) {
      this.oauthService.initLoginFlow();
    } else if (environment.isHomolog) {
      this.adfsAuthentication = true
    }
  }

  private loadPermissions(loginResult: ILoginResult, targetRedirectUrl: string): Observable<any> {
    return of(this.profileService.getProfilePermissions(loginResult.role)
      .subscribe(response => {
        if (response.warningLevel === WarningLevel.Success) {
          this.context.cache.setTemporaryValue('permissions', response.content);
        }
      }, (error) => {
        this.error(error.error);
      },
        () => {
          this.handleLogin(loginResult, targetRedirectUrl)
        }))
  }

  private handleLogin(loginResult: ILoginResult, targetRedirectUrl: string) {
    if (!FW.isNull(loginResult)) {
      if (FW.isNullOrBlank(targetRedirectUrl)) {
        $(".grecaptcha-badge").removeClass("show");
        const queryParams: IQueryParams[] = [];
        queryParams.push({
          name: "idScope",
          value: loginResult.currentScope,
          type: "query",
        });
        this._hasIpAccessControl = loginResult.hasIpAccessControl;
        this._hasTwoFactorAuth = loginResult.hasTwoFactor;
        if (!loginResult.hasIpAccessControl) {
          if (loginResult.hasTwoFactor) {
            $("#exampleModalCenter").modal("show");
            this._twoFactor.userAccount = loginResult.id;
            this._twoFactor.name = loginResult.name;
            this._twoFactor.email = loginResult.email;
            this._twoFactor.scope = this._scopeKey;
            this._twoFactor.authToken = this._cryptoText;
          } else if (loginResult.role === 63 && !!loginResult.firstAccess) {
            this.context.navigation.changeRoute('main/alterpassword');
          } else if (loginResult.passwordRecoveryProcess) {
            Storage.setData("password_recovery", loginResult.passwordRecoveryProcess);
            this.context.navigation.changeRoute('main/alterpassword');
          } else if (Storage.getString('votation')) {
            const votation = Storage.getString('votation');
            Storage.setString('votation', '');
            this.context.navigation.changeRoute('main/votation', { 'as': votation });
          }
          else {
            if (loginResult.role === 255 || loginResult.role === 127 || loginResult.role === 63) {
              this.context.navigation.changeRoute(this._redirectPage);
            } else {
              this.context.navigation.changeRoute('/');
            }
          }
          localStorage.removeItem('recoveryTime');
        } else {
          if (loginResult.hasTwoFactor) {
            $("#exampleModalCenter").modal("show");
            this._twoFactor.userAccount = loginResult.id;
            this._twoFactor.name = loginResult.name;
            this._twoFactor.email = loginResult.email;
            this._twoFactor.scope = this._scopeKey;
            this._twoFactor.authToken = this._cryptoText;
          } else {
            this.validateIpAddress();
          }
        }
      } else {
        this.context.navigation.redirect(targetRedirectUrl);
      }
    }
  }
}

