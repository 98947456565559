import { Injectable } from "@angular/core";

import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private tempCache: any = {};

    public containsTemporaryKey(key: string): boolean {
        return (key in this.tempCache) || localStorage.getItem(key) !== null;
    }

    public getTemporaryValue<T>(key: string): T {
        if (!this.containsTemporaryKey(key)) { return null; }

        const value = this.tempCache[key] || localStorage.getItem(key);

        if (FW.isNullOrEmpty(value)) { return null; }

        // Se o valor foi armazenado como string no localStorage, tente fazer o parsing
        try {
            return JSON.parse(value) as T;
        } catch {
            return value as T;
        }
    }

    public setTemporaryValue(key: string, value: any): void {
        this.tempCache[key] = value;

        // Armazena no localStorage em formato de string
        localStorage.setItem(key, JSON.stringify(value));
    }

    public clearTemporaries(): void {
        this.tempCache = {};

        // Limpa todas as chaves do localStorage relacionadas
        Object.keys(localStorage).forEach(key => {
            localStorage.removeItem(key);
        });
    }
}
