import { Injectable } from "@angular/core";
import { BaseService } from "src/app/framework/base-service";
import { APIService } from "../application/api.service";
import { ContextService } from "../application/context.service";
import { Observable, of } from "rxjs";
import { IAppContext } from "src/app/framework/interfaces";
import * as FW from '../../framework/core';
import { IPermission, IProfile } from "src/app/models/IProfile";
import { IQueryParams } from "src/app/models/IQueryParams";

@Injectable({
    providedIn: 'root'
})

export class ProfileService extends BaseService {
    private baseUrl: string;

    public get serviceName(): string { return 'Role' };

    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = '/security/profile'
    }

    public getById(id: string): Observable<IProfile> {
        const params: IQueryParams[] = [
            { type: 'url', value: id, name: '' }
        ];

        const callURL = super.fabricateURL(`${this.baseUrl}`, params);

        const context: IAppContext = this.context.join(this.serviceName);

        return this.apiService.get(context, callURL);
    }

    public getListByFilter(params: IQueryParams[]): Observable<FW.Envelope<IProfile[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}`, params);

        const context: IAppContext = this.context.join(this.serviceName);

        if (callURL) {
            return this.apiService.get(context, callURL);
        } else {
            return of(null);
        }
    }

    public save(role: IProfile): Observable<FW.Envelope<IProfile>> {
        const context: IAppContext = this.context.join(this.serviceName);

        const postEnvelope = new FW.Envelope(role);

        return this.apiService.post(context, `${this.baseUrl}/save`, postEnvelope);
    }

    public update(role: IProfile): Observable<FW.Envelope<IProfile>> {
        const context: IAppContext = this.context.join(this.serviceName);

        const postEnvelope = new FW.Envelope(role);

        return this.apiService.put(context, `${this.baseUrl}/update`, postEnvelope);
    }

    public remove(id: string): Observable<any> {
        const params: IQueryParams[] = [
            { type: 'url', name: '', value: id }
        ];

        const callURL = super.fabricateURL(`${this.baseUrl}`, params);

        const context: IAppContext = this.context.join(this.serviceName);

        return this.apiService.delete(context, callURL);
    }

    public getProfilePermissions(role: number): Observable<FW.Envelope<IPermission[]>> {
      const params: IQueryParams[] = [];

      const callURL = super.fabricateURL(`${this.baseUrl}/${role}/permissions`, params);
      const context: IAppContext = this.context.join(this.serviceName)
      return this.apiService.get(context, callURL);
  }
}
