import { AuthService } from './application/auth.service';
import { LoaderService } from './application/loader.service';
import { APIService } from './application/api.service';
import { MessageService } from './application/message.service';
import { HttpErrorHandler } from './application/httperrorhandler.service';
import { ViewLoader } from './application/viewloader.service';
import { TestService } from './business/test.service';
import { CacheService } from './application/cache.service';
import { ContextService } from './application/context.service';
import { NavigationService } from './application/navigation.service';
import { TipifierService } from './application/typifier.service';
import { SecurityService } from './business/security.service';
import { MessageSwalService } from './application/messageswal.service';
import { UserService } from './business/user.service';
import { AuthenticateService } from '../pages/security/login/services/authenticate.service';
import { FirstAccessService } from '../components/user/services/first-access.service';
import { UserPasswordService } from '../components/user/services/user-password.service';
import { AdministratorService } from './business/administrator.service';
import { ProfileService } from './business/profile.service';

export const serviceProviders = [
    APIService,
    AuthService,
    CacheService,
    ContextService,
    HttpErrorHandler,
    LoaderService,
    MessageService,
    NavigationService,
    SecurityService,
    TestService,
    TipifierService,
    ViewLoader,
    MessageSwalService,
    UserService,
    AuthenticateService,
    NavigationService,
    FirstAccessService,
    UserPasswordService,
    AdministratorService,
    ProfileService
];
