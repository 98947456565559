import { SecurityService } from 'src/app/services/business/security.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextService } from 'src/app/services/application/context.service';
import { environment } from 'src/environments/environment';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { WarningLevel } from 'src/app/framework/enums';
import { Observable, of } from 'rxjs';
import { ILoginResult } from 'src/app/models/ILoginresult';
import { ProfileService } from 'src/app/services/business/profile.service';

@Component({
  selector: 'app-adfs-callback',
  templateUrl: './adfs-callback.component.html',
  styleUrls: ['./adfs-callback.component.scss']
})
export class AdfsCallbackComponent implements OnInit {
  private token = '';

  constructor(
    private route: ActivatedRoute,
    private context: ContextService,
    private security: SecurityService,
    private profileService: ProfileService,
    private messageService: MessageSwalService) {
    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.token = params['token'];
      }
    });
  }

  ngOnInit() {
    const scope = this.context.authService.currentScope.key;

    if (!!this.token) {
      this.security.impersonate(scope, this.token).subscribe((loginResult) => {
        this.context.authService.initSession(
          loginResult.authToken,
          new Date(loginResult.expiresAt),
          loginResult.id,
          loginResult.name,
          loginResult.email,
          loginResult.privilege,
          loginResult.role
        );
        this.loadPermissions(loginResult)
      }, (error) => this.error(error.error));
    } else {
      this.goToLogin(scope);
    }
  }

  goToLogin(scope: string) {
    if (environment.isHomolog) {
      this.context.navigation.changeRoute("/login", { "scope": scope });
    } else {
      this.context.navigation.changeRoute("/login");
    }
  }

  private error(error: string): void {
    this.context.authService.ssoNotAuthorized = true;
    this.messageService.displayMessage({ warninglevel: WarningLevel.Error, message: error }, () => { });
    this.goToLogin(this.context.authService.currentScope.key);
  }

  private loadPermissions(loginResult: ILoginResult): Observable<any> {
    return of(this.profileService.getProfilePermissions(loginResult.role)
      .subscribe(response => {
        if (response.warningLevel === WarningLevel.Success) {
          this.context.cache.setTemporaryValue('permissions', response.content);
        }
      }, (error) => {
        this.error(error.error);
      },
        () => {
          this.context.navigation.changeRoute("/main/meeting");
        }))
  }


}
